import React, {useEffect, useReducer, createContext, useState} from 'react'

import {useSearchParams, useParams} from 'react-router-dom'
import { getStrings } from './utilities/lib';
import { ThemeProvider, createTheme } from '@mui/material';

export const DataContext = createContext(null);

const initialData = {
    current: {
        full: null,
        party: null,
        declaration: null,
    },
    prev: {
        full: null, 
        party: null, 
        declaration: null
    },
    results: {
        full: null, 
        party: null, 
        declaration: null
    },
    electionString: '', 
    config: null,
    lang: 'en',
    counter: 0, 
    currentYear: true, 
    error: false

}

const DataContextProvider = (props) => {
    const [data, setData] = useState(initialData)
    const [darkModeState, setDarkMode] = useState(false)
    const {prov, year} = useParams();

    let [searchParams] = useSearchParams();
    let lang = searchParams.get("lang") ? searchParams.get("lang") : "en"
    let darkmodeParam = searchParams.get('darkmode') && searchParams.get("darkmode")
    const STRINGS = getStrings(lang)


    const getStaticData = () => {
        let eYear = year ? year : "2020"
        let eProv = prov ? prov : "nb"

        Promise.all([
            fetch(`data/${eProv}/data/${eYear}/${eProv}_ridingResults.json`, {cache: 'no-cache'})
                .then(res=>{
                    return res.json();
                })
                .then(json=>{
                    return json
                })
                .catch(e=>{
                    console.log('error fetching riding results')
                    return;

                }),
            fetch(`data/${eProv}/data/${eYear}/${eProv}_partyResults.json`, {cache: 'no-cache'})  //http://electorfederal.blcloud.net/api/party/result/overall/(all)/json
                .then(res=>{
                    return res.json();
                })
                .then(json=>{
                    return json
                })
                .catch(e=>{
                    console.log('error fetching party results')
                    return null

                }),
            fetch(`data/${eProv}/data/${eYear}/${eProv}_declaration.json`, {cache: 'no-cache'})  // //http://electorfederal.blcloud.net/api/OverallResult/?format=json
                .then(res=>{
                    return res.json();
                })
                .then(json=>{
                    return json
                })
                .catch(e=>{
                    console.log('error fetching declaration results')
                    return null

                }),
            fetch(`data/${eProv}/data/${eYear}/${eProv}_prevParty.json`)
                .then((res)=> {
                    return res.json();
                })
                .catch(e=>{
                    console.log('previous results do not exist')
                    return null
                }),
            fetch(`data/${eProv}/data/${eYear}/${eProv}_prevDeclaration.json`)
                .then((res)=> {
                    return res.json();
                })
                .catch(e=> {
                    console.log('previous declaration does not exist')
                    return null
                }),
            fetch(`data/${eProv}/data/${eYear}/${eProv}_leaders.json`)
                .then(res=>{
                    return res.json();
                })
                .then(json=>{
                    return json
                })
                .catch(e=>{
                    console.log('Please include party leader data')
                    return null
                }),
            fetch(`data/${eProv}/data/${eYear}/${eProv}_config.json`)  // //http://electorfederal.blcloud.net/api/OverallResult/?format=json
                .then(res=>{
                    return res.json();
                })
                .then(json=>{
                    return json
                })
                .catch(e=>{
                    console.log('Please add election config')
                    return null
                })
            ])
            .then(res=>{
                if (res[0] && res[1]) {
                setData(prevData=>({
                    ...prevData,
                    type: 'SET_INITIAL',
                    current: {
                        full: res[0], 
                        party: res[1],
                        declaration: res[2],    
                    },
                    prev: {
                        party: res[3], 
                        declaration: res[4],     
                    },
                    results: {
                        full: res[0], 
                        party: res[1],
                        declaration: res[2]
                    },
                    leaders: res[5],
                    config: res[6] , 
                    STRINGS: STRINGS, 
                    electionString: `${eProv}_${eYear}`,
                    lang: lang, 
                    error: false
                }))
                } else {
                    setData(prevData=>({
                        ...prevData, 
                        error: true, 
                        config: res[6]
                    }))
                }
            }).catch(e=>{
                console.log('error', e)
                setData(prevData=>({
                    ...prevData, 
                    error: true
                }))
            })
    }

    const getDynamicData = () => {

        let eYear = year ? year : "2020"
        let eProv = prov?prov : "nb"

        console.log('fetching data')
        Promise.all([
            fetch(`data/${eProv}/data/${eYear}/${eProv}_ridingResults.json`, {cache:"no-cache"})
                .then(res=>{
                    return res.json();
                })
                .then(json=>{
                    return json
                })
                .catch(e=>{
                    console.log('error fetching riding results')
                    return;
                }),
            fetch(`data/${eProv}/data/${eYear}/${eProv}_partyResults.json`, {cache:"no-cache"})  //http://electorfederal.blcloud.net/api/party/result/overall/(all)/json
                .then(res=>{
                    return res.json();
                })
                .then(json=>{
                    return json
                })
                .catch(e=>{
                    console.log('error fetching party results')
                    return;

                }),
            fetch(`data/${eProv}/data/${eYear}/${eProv}_declaration.json`, {cache:"no-cache"})  // //http://electorfederal.blcloud.net/api/OverallResult/?format=json
                .then(res=>{
                    return res.json();
                })
                .catch(e=>{
                    console.log('error fetching declaration results')
                    return;
                })
            ])
            .then(res=>{
                if (!res[0] || !res[1]) {
                    console.log('error ')
                    setData(prevData=>({
                        ...prevData, 
                        error: true
                    }))
                } else {
                setData(prevData=>({
                    ...prevData,
                    type: 'SET_INITIAL', 
                    current: {
                        full: res[0], 
                        party: res[1],
                        declaration: res[2]
                    }, 
                    results: prevData.currentYear ? {
                        full: res[0], 
                        party: res[1],
                        declaration: res[2]
                    } : prevData.results, 
                    error: false
                }))}
            }).catch(e=>{
                console.log('error', e)
                setData(prevData=>({
                    ...prevData, 
                    error: true
                }))
            })
    }

    useEffect(()=> {
        getStaticData()
		const forceDarkMode = darkmodeParam ? (darkmodeParam === 'true' ? true : false) : null
        
        let darkMode =  false
        if (forceDarkMode !== null) {
            darkMode = forceDarkMode
        }
        let messageHandler = (event) => {
            try {
                if (event.data.source === "react-devtools-bridge" || event.data.source === "react-devtools-content-script") return 
                // console.log('messageEvent', event, event.data.message)
                if (event.origin !== 'https://globalnews-ca-develop.go-vip.net' && event.origin !== 'https://globalnews.ca' && event.origin !== "http://localhost:3000" && event.origin !== "https://bannisterlake.com") {
                    console.log('incorrect origin', event.origin)
                    return null;
                }
                if (event.data === 'light-theme' || event.data === 'dark-theme') {
                    switch(event.data) {
                        case 'dark-theme': 
                            darkMode = true;
                            break;
                        case 'light-theme': 
                            darkMode = false;
                            break;
                        default: 
                            break;
                    }
                    setDarkMode(darkMode)
                    document.documentElement.classList.toggle('dark', darkMode);
                    localStorage.setItem('darkMode', darkMode)
                    if (document.getElementsByTagName("html")[0]) {
                        document.getElementsByTagName("html")[0].style.backgroundColor = darkMode ? "#231F20" : 'white' ;
                    }
                }
            } catch (e) {
                console.log('error postmessage')
                return;
            }
        }
        window.addEventListener("message", messageHandler)
        
        setDarkMode(darkMode)
        document.documentElement.classList.toggle('dark', darkMode);
        localStorage.setItem('darkMode', darkMode)
        if (document.getElementsByTagName("html")[0]) {
            document.getElementsByTagName("html")[0].style.backgroundColor = darkMode ? "#231F20" : 'white' ;
        }
        return ()=>{
            window.removeEventListener("message", messageHandler)
        }

    }, [])

    useEffect(()=> {
        let interval;
        if (data.config) {
            console.log('updating every ' + (data.config.timer ? data.config.timer : 30000) + 'ms');
            interval = setInterval(()=>{
                getDynamicData();
            },
            (data.config.timer ? data.config.timer : 30000)
            // 5000
            )
            // ,(3000))
        }
        return () => {
            console.log('unmount')
            clearInterval(interval)
        }
    }, [data.config])   

    const changeYear = (yearButton) => {
        if (data.currentYear !== yearButton) {
            setData(prevData=>({
                ...prevData,
               currentYear: yearButton, 
               results: prevData[yearButton?'current':'prev']
            }))
        }
    }

    const theme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#231F20'
            },
            text: {
                primary: '#F2F2F2',
                secondary: '#DBD9D9',
              },
            background: {
                default: '#231F20',
                paper: '#231F20',
              },
            backgroundImage: {
                default: 'none'
            },

          },
	  });

    return (
        <DataContext.Provider value={{data, changeYear}}>
            <ThemeProvider theme={darkModeState ? theme : {}}>
            {props.children}
            </ThemeProvider>
        </DataContext.Provider>
    )
}

export default DataContextProvider