import React, { useContext } from 'react';
import {IconButton} from '@mui/material'
import {DataContext} from '../../dataContext'
import {Launch} from '@mui/icons-material'
import getColorOverride from '../../utilities/functions';
import { useParams } from 'react-router';

function ResultsBox({results, filter, handleSelectRiding}) {
    const {data} = useContext(DataContext)
    const {prov, year} = useParams()

    const getColor = (candidate) => {
        const colorData = {
            color: "rgb(114, 118, 133)",
            nameShort: "OTH" 
        }
        try {
            const partyCode = candidate.partyCode
            // let override = getColorOverride(partyCode, `${prov}_${year}`)
            let partyInfo = data.config.parties.filter(party => {
                return party.nameShort === partyCode
            })[0]
            if (partyInfo) {
                colorData.color = partyInfo.win
                colorData.nameShort = partyInfo.nameShort
            }
            return colorData
        } catch (e) {
            return colorData
        }
    }


    const getRidingResults = (ridingName) => {
        let riding =  data.current.full.data.find(el=>el.name === ridingName)
        handleSelectRiding(riding);
    }

    return (
        <div className={'classes.resultsRoot h-full overflow-scroll cursor-pointer'}>
            <div className={'classes.candidateContainer px-1 flex flex-col gap-2'}>
            {(filter && results.data.length > 0) ? results.data.map((candidate,i)=>{
                const partyData = getColor(candidate)
                return (
                <div key={i} id="filteredCandidate" className={'classes.candidateRoot dark:border-dark-border dark:text-dark-text grid grid-cols-2 gap-2'} onClick={()=>getRidingResults(candidate.riding)}>
                    <div className={'classes.candidateLeftDiv flex flex-col gap-1'}>
                        <div id="candidateRiding" className='text-base sm:text-xl font-bold font-light'>                            
                            <span>{candidate.riding}</span>
                            <IconButton  aria-label={`More information on ${candidate.riding} riding opens in a modal`} onClick={()=>getRidingResults(candidate.riding)}>
                                <Launch/>
                            </IconButton>
                        </div>
                        <div className={'classes.candidateName'}>
                            <div id="candidateName" className='text-base sm:text-lg'>{candidate.firstName + ' ' + candidate.lastName}</div>
                            <div id="candidateParty" className={'font-semibold text-lg'}style={{ color: partyData.color}}>{candidate.partyCode}</div>
                         </div>
                        
                    </div>
                    <div className={'classes.candidateRightDiv text-right flex flex-col gap-2'}>
                        <div id='pollsDiv' className='text-xs font-mono'>
                            <div><strong>{candidate.pollsReported}/{candidate.pollsTotal}</strong> polls reporting</div>
                        </div>
                        <div className={'classes.votesDiv'}>
                            <div className='font-mono'>
                                <div id='votesLabel' className="font-light ">Leading By: </div>
                                <div><strong>{candidate.lead.toLocaleString('en')}</strong> votes</div>
                                <strong>{candidate.leadPercent}%</strong>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>)
            }):
            <div>Filtered results data will be made available as results come in.</div>
            }
            </div>
        </div>
    )
}

export default ResultsBox
