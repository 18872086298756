import React, {useState, useEffect, useContext, useRef} from 'react'
import {DataContext} from '../../dataContext'
import { useParams } from 'react-router';
import LiveUpdates from '../../utilities/LiveUpdates';
import getColorOverride from '../../utilities/functions'
import { PlayCircleFilled } from '@mui/icons-material';
import Party from '../../utilities/Party';

// const styles = makeStyles({
// 	barchartApp: {
// 		height: 100,
// 		position: "relative",
// 	},
// 	main: {
// 		// fontFamily: 'Roboto',
// 		height: '100%',
// 		display: 'flex',
// 		position: 'relative',
// 		flexDirection: 'column',
// 		padding: '10px 10px',
// 	},
// 	titleRow: {
// 		display: 'flex', 
// 		padding: '0px 5px 5px 5px',
// 		justifyContent: 'space-between', 
// 		gap: 10
// 	},
// 	declaration: {
// 		fontSize: 14
// 	},
// 	link: {
// 		fontSize: 14, 
// 		textDecoration: 'none !important', 
// 		display: 'flex', 
// 		alignItems: 'center',
// 		gap: 10, 
// 		'& svg': {
// 			width: '0.75em', 
// 			height: '0.75em'
// 		}
// 	},
// 	title: {
// 		fontSize: 24,
// 		// paddingBottom: 10, 
// 		fontWeight: 'bold'
// 	},
// 	declarationText: {
// 		paddingTop: 5,
// 		'& #party' : {
// 			fontWeight: 'bold'
// 		},
// 		'& #majority': {
// 			fontSize: 14
// 		}
// 	},
// 	barchart: {
// 		fontSize: 14,
// 		display: 'flex',
// 		padding: '15px 0px 10px 0px',
// 		flexDirection: 'column-reverse',
// 		flexWrap: 'wrap',
// 		flex:3, 
// 	},
// 	partyMap: {
// 		fontSize: 16,
// 		display: 'flex',
// 		flex: 2,
// 		alignItems: 'center',
// 		overflowX: 'auto',
// 		// margin: "15px 0px", 
// 		gap: 5
// 	},
// 	update: {
// 		fontSize: 10,
// 		padding: '5px 0px 0px 5px'

//     },
//     partyContainer: {
//         // width: '20%',
// 		flex:1,
// 		display: 'flex',
//         alignItems: 'center',
// 		fontSize: 14,
//         letterSpacing: 2,
// 		height: 'fit-content',
//         padding: '5px 2%',
//         borderRadius: 5,
//         color: 'white',
//         justifyItems: 'center',
// 		justifyContent: 'space-between',
//     },
//     popVote:{
//         // paddingTop: 5,
//         fontWeight: 'bold',
//         fontSize: 14
//     }, 
	
// })


export default function App() {
    const {data} = useContext(DataContext)
	const [parties, setParties] = useState([])
	const [declarationText, setDeclarationText] = useState('')
	const [majorityText, setMajorityText] = useState('')


	const {prov, year} = useParams()

	const {results} = data


    useEffect(()=> {
        if (results.party) {
			let total = 0
			setParties([])
            results.party.partyResults.sort((a,b)=>{
				if (a.seats > b.seats) {
					return -1
				} else if (a.seats < b.seats) {
					return 1
				} else return 0
			}).map((party, i)=>{
				const colors = getColorOverride(party.nameShort, `${prov}_${year}`) 
				console.log(colors)
				setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, color: colors ? colors.color : party.color, font: colors ? colors.font : "black", votesPercent: party.votesPercent,votesTotal: party.votes}])
				
			})
			if (results.declaration.overallResult.partyName || results.declaration.overallResult.resultText) {
				let text = results.declaration.overallResult.partyNameShort + ' ' + results.declaration.overallResult.resultText;
				if (text===declarationText) {
					return;
				} else {
					setDeclarationText(text)
				}
			} else 
			setDeclarationText('')
		}


    }, [results])

    return (
        <div className={'classes.barchartApp h-full '} >
			{data.error && <div className="p-2 mx-auto">Election Data Currently Unavailable</div>}
            {(data.config && results.party && !data.error) && 
             <div className={'classes.main h-full max-h-48 flex flex-col'} tabIndex="0" aria-labelledby='widgetTitle'>
                <div className={'classes.titleRow flex justify-between'}>
					<div className={'classes.declaration'} tabIndex="0" role="none" aria-label={`election declaration ${declarationText}`}>
						{declarationText && <strong>{declarationText.toUpperCase()} 
						</strong>} {majorityText}
					</div>
					<div className={'hover:underline'} >
						<a className={'classes.link text-primary-900 text-sm flex items-center gap-1'}href="">View Full Coverage <PlayCircleFilled fontSize='small'/> </a>
					</div>
				</div>
                <div className={'classes.partyMap flex gap-2 pt-3'}>
                    {parties.map(party=>{
						return <Party key={party.id} party={party}/>
					})}
                </div> 
				{data.config.election_day &&
					<LiveUpdates time={results.party.generated} />
				}
            </div>
            }
        </div>
    )
}
