const STRINGS = {
    "en": {
        CONTRIBUTORS: "contributors",
        SIDEBARTITLE: "Select a riding to view results",
        UPDATETEXT: "Last updated",
        SEARCHTEXT: "Search for a riding...",
        POLLSREPORTING: "polls reporting",
        VOTESREPORTING: "votes reporting",
        TOTALVOTES: "total votes",
        LEADING: "leading", 
        AND: "and", 
        ELECTED: "elected",
        INCUMBENT: "incumbent", 
        POPULARVOTE: "Popular Vote",
        GAINSLOSSES: "Gains / Losses", 
        LEADINGBY: "Leads by", 
        WINSBY: "Wins by", 
        VOTES: "votes", 
        NORESULTS: "No Results", 
        MAJORITYTEXT: "seats needed for majority", 
        ELECTIONDECLARATION: "election declaration", 
        MAPRESULTS: "map showing election results", 
        LEADINGORELECTED: "leading or elected in", 
        RIDINGS: 'ridings', 
        BUTTONGROUP: "click buttons to change election results year", 
        AUTOCOMPLETETEXT: "Start typing the name of a riding, then use arrow keys to select it from the auto-complete list below."
    }, 
    "fr": {
        CONTRIBUTORS: "contributeurs",
        SIDEBARTITLE: "Sélectionnez une circonscription pour voir les résultats",
        UPDATETEXT: "Dernière mise à jour",
        SEARCHTEXT: "Rechercher une circonscription",
        POLLSREPORTING: "rapports de sondages",
        TOTALVOTES: "total des votes",
        ELECTED: "Élu",
        INCUMBENT: "titulaire", 
        POPULARVOTE: "Vote populaire",
        GAINSLOSSES: "Gains / Pertes", 
        LEADINGBY: "En tête par", 
        WINSBY: "Gagne par", 
        VOTES: "voix", 
        NORESULTS: "Aucun Résultat", 
        MAJORITYTEXT: "sièges nécessaires pour la majorité", 
        LEADING: "en tête", 
        AND: "et",
        ELECTIONDECLARATION: 'déclaration électorale',
        MAPRESULTS: "carte montrant les résultats des élections",
        LEADINGORELECTED: "en tête ou Élu dans", 
        RIDINGS: 'circonscriptions', 
        BUTTONGROUP: "cliquez sur les boutons pour changer l'année des résultats des élections", 
        AUTOCOMPLETETEXT: "Commencez à saisir le nom d'une circonscription, puis utilisez les touches fléchées pour la sélectionner dans la liste de saisie semi-automatique ci-dessous"

    }
}   

export const getStrings = (lang) => {
    if (lang === 'fr') {
        return STRINGS['fr']
    } else {
        return STRINGS['en']
    }
}
