import React, {useState, useEffect} from 'react'


function Bar(props) {
    const { color, votes, leading, ...other } = props;
    return <div className={votes ? `bar max-h-6 border relative flex overflow-hidden box-border dark:border-0 border-white flex-1 min-h-1` : `bar border max-h-6 dark:border-0 border-white flex-1 hidden min-h-1`} style={{width: `${votes}%`, borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}} {...other}>
        <div className="elected  " style={{backgroundColor: color, width: `${((votes-leading)/votes)*100}%`}} />
        <div className="leading " style={{backgroundColor: color, opacity: 0.6, width: `${(leading/votes)*100}%`}} />
    </div>;
}

const MajorityMeter = ({data, longText, seatTotal, majority, configParties, limit}) => {
    
    const [maxSeats, setMaxSeats] = useState(25)
    const [majorityPosition, setMajorityPosition] = useState(50);
    
    useEffect(()=> {
        if (data) {
            let leadingParty = data.partyResults[0];
            if (leadingParty.seats >= majority) {
                setMaxSeats(leadingParty.seats);
                setMajorityPosition(majority/leadingParty.seats*100)
            } else {
                setMaxSeats(majority)
                setMajorityPosition(majority/majority*100)
            }
        }
    }, [data, seatTotal, majority])

    const getColor = (partyName) => {
		let color = {
			color: '#D8D9D9', 
			text: 'black'
		}
		try {
			if (configParties) {
				let parsedParty = configParties.find(el=>el.nameShort === partyName)
				if (parsedParty) {
						color = {
							color: parsedParty.chiclet, 
							text: parsedParty.text
						}
						return color
					}
				} 
			let party = data.partyResults.find(el=>el.nameShort === partyName)
			if (party) {
				color.color = party.color
				color.text = 'white'
			}
			return color
		} catch (e) {
            console.log('error getting color', e)
			return color
		}
	}

    return (
        <div className={'classes.meter h-full flex flex-col justify-center relative py-2 w-11/12 min-h-[50px]'}>
            {data && data.partyResults.sort((a,b)=>{
                    if (a.seats > b.seats) {
                        return -1
                    } else if (a.seats < b.seats) {
                        return 1
                    } else return 0
                }).map((party, i)=>{
                    let parsedColor = getColor(party.nameShort)
                    if (i < 4) {
                        return <Bar key={i} color={parsedColor.color} leading={(party.leading/maxSeats)*100} votes={party.seats > 0 ? ((party.seats/maxSeats)*100 ): 0.5} />
                    } 
                    if (!limit) {
                        return <Bar key={i} color={parsedColor.color} leading={(party.leading/maxSeats)*100} votes={party.seats > 0 ? (party.seats/maxSeats)*100 : 0.5} />
                    }
            })    
            }
            {longText && <div aria-hidden="true" className={'hidden xs:block dark:text-dark-text absolute -top-6 font-bold'} style={{right: `${100-majorityPosition}%`}}>{majority} seats needed for majority</div>}
            {<div aria-hidden="true"  className={longText ? 'block xs:hidden dark:text-dark-text absolute -top-5 font-bold' : 'text-xs dark:text-dark-text absolute -top-5 font-bold font-light'} style={{left: `${majorityPosition}%`}}>{majority}</div>}
            <div className={'classes.majorityLine dark:text-dark-text absolute h-full dark:border-dark-text2 border-l-black border-l-2 pr-1 bottom-1'} style={{left: `${majorityPosition}%`}}/>
        </div>
    );
}
export default MajorityMeter;