import React, { useState, useEffect, useContext } from 'react';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'

import {DataContext} from '../../dataContext'

import Loading from '../../utilities/Loading'
import getColorOverride from '../../utilities/functions'

import { useParams} from 'react-router-dom'
import ResultsBox from './ResultsBox';
import SimpleDialog from './SimpleDialog';

function FilteredResultsApp() {
    const {data} = useContext(DataContext)
    const [filter, setFilter] = useState(null)
    const [results, setResults] = useState(null)
    const [selectedRidingResults, setSelectedRiding] = useState('')
    const [show, toggleShow] = useState(false)
    const [loadHeadshots, toggleLoadHeadshots] = useState(false)
    const [loading, toggleLoading] = useState(true)
    let {prov, year, loadfilter} = useParams()

    let candidates = 0

    const [selects, setSelects] = useState([])

    const handleChange = (value) => {
        if (value) {
            setFilter(value);
        }
    }

    const handleSelectRiding = (ridingResults) => {
        setSelectedRiding(ridingResults)
        toggleShow(true)
    }


    useEffect(()=> {
        try {
            if (selectedRidingResults) {
                let newData = data.current.full.data.find(contest => {
                    return contest.name === selectedRidingResults.name
                })
                if (newData) {
                    handleSelectRiding(newData)
                } 
            }    
        } catch (e) {
            console.log('error setting new data')
        }
    },[data])

    const getData = (list) => {
        if (list.length > 0) {
            let requests = list.map(el=>fetch(`data/${prov}/data/${year}/filtered/${el.filename}`, {cache: 'no-cache'}).then(res=>res.json()));
            Promise.all(requests).then(res=>{
                res.forEach((el,i)=>{
                    setResults(state=>({...state, [`${list[i].label}`]: el}))
                })

            }).then(()=>{
                toggleLoading(false);
            })
        } else {
            setResults(null)
        }
    }

    useEffect(()=> {
        let interval;
        if (data.config) {
            getData(data.config.filteredResults)
            getSelect()
            setInterval(()=> {
                getData(data.config.filteredResults)
            }, data.config.timer)

            if (loadfilter) {
                const findFilter = data.config.filteredResults.find(el=>el.urlSlug.toLowerCase() === loadfilter.toLowerCase())
                if (findFilter) {
                    handleChange(findFilter.label)
                }
            }
        } else {
            toggleLoading(false)
        }
        return () => {
            clearInterval(interval)
        }
    }, [data.config])
    

    const GetResults = () => {
        if (data.config.filteredResults.length > 1) {
            if (results[filter]) {
                return (
                    <ResultsBox handleSelectRiding={handleSelectRiding} results={results[filter] && results[filter]} filter={filter}/>
                )
            } else {
                return (
                    <p >Select Filter to view Results</p>
                )
            }
        } else {
            return (
                <ResultsBox handleSelectRiding={handleSelectRiding} results={results[Object.keys(results)[0]] && results[Object.keys(results)[0]]} filter={data.config.filteredResults[0].label}/>
            )
        }
    }

    
    const handleClose = () => {
        toggleShow(false)
        setSelectedRiding(null)
        toggleLoadHeadshots(false)
        candidates = 0
    }
    

    const getColor = (candidate) => {
        const partyCode = candidate.partyCode
        let partyInfo = data.current.party.partyResults.find(party => {
            return party.nameShort === partyCode
        })
        const colors = getColorOverride(partyCode, `${prov}_${year}`) 
        if (partyInfo) {
            if (colors) {
                partyInfo.color = colors.color
            }
            return partyInfo
        } else 
        return {
            color: "#C0C0C0",
            name: "Other",
            nameShort: "OTH"
        }
    }
    const handleLoad = () => {
        if (selectedRidingResults) {
            candidates ++;
            if (candidates === selectedRidingResults.results.length) {
                toggleLoadHeadshots(true)
            }
    
        }
    }

    const getSelect = () => {
        let list = []
        if (data.config.filteredResults) {
            for (let i=0; i< data.config.filteredResults.length;) {
                list.push(<MenuItem key={i} value={data.config.filteredResults[i].label}>{data.config.filteredResults[i].label}</MenuItem>)
                i++;
            }
        } 
        setSelects(list)
    }

    if (loading) {
        return (
            <Loading />
        )
    } else {
        return (
            <div className={'classes.root h-full flex flex-col'}>
                {data.error ? <div className="p-2 mx-auto">Election Data Currently Unavailable</div> :
                results ? 
                <>
                {data.config.filteredResults.length > 1 && <div className={'classes.selectContainer py-2 '}>
                    <FormControl className={'classes.formControl w-64 max-w-full'}>
                        <InputLabel shrink={filter ? true : false} className="dark:bg-dark-bg bg-white px-1">Results Filter</InputLabel>
                        <Select 
                            value={filter} 
                            onChange={(e) => handleChange(e.target.value)}
                            id="grouped-select">
                            {selects}
                        </Select>
                    </FormControl>
                </div> }
                    <div className={'classes.resultsContainer flex-grow relative overflow-hidden'}>
                        {results && <GetResults/>}
                        {selectedRidingResults && <SimpleDialog getColor={getColor} results={selectedRidingResults} open={show} showHeads={loadHeadshots} handleLoad={handleLoad} handleClose={handleClose}/>}
                    </div>
                </>
                :
                <Loading/>}
            </div>
        )
    }

}
export default FilteredResultsApp
