import React from 'react'

export default function SeatMap({seats, classNames}) {

    const Seat = (props) => {
      // console.log(props)
		return (
			<div key={`${props.party}-${props.el}`} className={'classes.seat dark:border-dark-bg rounded-sm border-white border basis-4 flex-shrink'} style={{backgroundColor: props.color, opacity: props.leading ? 0.6 : 1}} />
		);
	}

    return (
    <div className={`${classNames?classNames : null} classes.seatMap my-auto flex flex-col flex-wrap max-h-36 h-full relative overflow-hidden`}>
        {seats.map((seat, i)=>{
                // return <div>aa</div>
                return <Seat key={seat.party+'-'+i} leading={seat.leading} party={seat.party} el={seat.id} color={seat.color}/>
            })
        }
    </div>
  )
}
