import React from 'react'

export default function Party(props) {
    const {party} = props
    return (
        <div   
            key={party.id} 
            className={'classes.partyContainer flex-1 flex items-center font-semibold text-sm rounded-sm flex-col xs:flex-row xs:justify-between xs:px-2'} 
            style={{backgroundColor: party.color, color: party.text }} 
            tabIndex="0" 
            aria-label={`${party.partyName} ${party.seats} seats won`}>

                <div>{party.partyName}</div>
                <div className={'classes.popVote text-base'}>{party.seats}</div>
        </div>
    );
}

