import React from 'react'
import { Dialog, List, ListItem, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { Check, CheckCircle } from '@mui/icons-material'
import { Close } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

export default function SimpleDialog({results, handleClose, getColor, open, handleLoad, showHeads}) {
    const {prov} = useParams()
    return (
        <Dialog fullScreen={window.screen.availWidth < 641} open={open} onClose={handleClose} >
            <div className={'classes.dialogRoot p-1 overflow-hidden relative h-full flex flex-col'} id="filterDiolog" >
                <div  className={'classes.header pb-4 flex justify-between'}>
                    <div>
                        <div className="dialogTitle text-2xl font-bold">
                            {results.name}
                        </div>
                        <div className={'classes.polls text-sm font-mono'}>
                            {results.pollsReported}/{results.pollsTotal} polls reporting
                        </div>
                    </div>
                    <IconButton aria-label="close" className={'classes.closeButton'} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </div>
                <div className={'classes.resultsList overflow-y-auto h-full'}>
                {/* <List tabIndex="0" > */}
                <TableContainer>
                    <Table size="small" stickyHeader className='overflow-auto'>
                        <TableHead className='h-8 uppercase ' >
                            <TableRow >
                                <TableCell className="font-mono text-xs dark:text-dark-text2 font-normal">Name</TableCell>
                                <TableCell className="font-mono text-xs dark:text-dark-text2 font-normal">Party</TableCell>
                                <TableCell align='right' className="font-mono text-xs pr-0 dark:text-dark-text2 font-normal">Votes</TableCell>
                                <TableCell align='right' className="font-mono text-xs pr-0 dark:text-dark-text2 font-normal">Share</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results.results.map((candidate,i)=>{
                            return (
                                <TableRow key={i} className={candidate.isElected ? 'h-12 items-center bg-secondary dark:bg-dark-secondary ' : 'h-12 items-center'}>
                                    <TableCell>
                                        <div 
                                            className={ 'flex gap-2 items-center'}
                                            >
                                            <div className="imageContainer rounded-full overflow-hidden h-8 w-8 min-w-fit"> 
                                                <img 
                                                    className={'w-8 h-8 '} 
                                                    alt="Candidate Headshot" 
                                                    // style={{width: props.showHeads ? '100%' : '0px'}} 
                                                    // onLoad={props.handleLoad}
                                                    onError={(e) => {e.target.src =`data/img/no_headshot.png`}} 
                                                    src={`data/${prov}/headshots/${candidate.cachedHeadFilename.split('.').slice(0,-1).join('').concat('.jpg')}`}/>
                                            </div>
                                            <div className={'classes.candidateName flex flex-col'}>
                                                <div className={candidate.isElected ? "font-bold text-lg":"font-normal text-lg"}>{candidate.name}{candidate.isIncumbent && '*'}</div>
                                            </div>
                                                {candidate.isElected && <Check />}
                                            </div>
                                        </TableCell>
                                        <TableCell 
                                            className={candidate.isElected ? 'flex-grow font-bold text-lg dark:text-dark-text': 'flex-grow font-normal text-lg dark:text-dark-text'} 
                                            // style={{color: partyData.color}}
                                            >{candidate.partyCode}
                                        </TableCell>
                                        <TableCell align='right' className={candidate.isElected ? 'font-bold text-lg': 'font-normal text-lg'}>{candidate.votes.toLocaleString('en')}</TableCell>
                                        <TableCell align='right' className={candidate.isElected ? 'font-bold text-lg': 'font-normal text-lg'}>{candidate.percent}%</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    {/* {results && results.results.map((candidate, i)=>{
                        let partyData = getColor(candidate)
                        return (
                            <ListItem key={i} className={'classes.candidateRoot px-0 flex h-[100px] relative gap-2'}>
                                <div style={{backgroundColor: partyData.color}} className={'classes.imageDiv h-full w-16 rounded'}>
                                    <img alt="Candidate Headshot" 
                                        className={'h-auto transition-all'}
                                        style={{width: showHeads ? '100%' : '0px'}} 
                                        onLoad={handleLoad} onError={(e) => { e.target.onError = null; e.target.src =`data/img/no_headshot.png`}} src={`data/${prov}/headshots/${candidate.cachedHeadFilename.split('.').slice(0,-1).join('').concat('.jpg')}`}/>
                                </div>
                                <div className="flex-grow flex justify-between gap-2 h-full">
                                    <div className={'classes.candidateLeftDiv'}>
                                        <div className={'classes.candidateName text-lg font-bold font-light'}>
                                            <div id="candidateLast">{candidate.name}</div>
                                        </div>
                                        <div id="partyCode" style={{fontWeight: 'bolder',color: partyData.color}}>{candidate.partyCode}</div>

                                        <div id="candidateIncumbent">{candidate.isIncumbent && 'Incumbent'}</div>
                                    </div>
                                    <div className={'classes.candidateRightDiv text-right flex flex-col '}>
                                        <div className={'classes.votesDiv flex flex-col justify-between flex-grow'}>
                                            <div id='votesPercent' className="text-2xl font-bold font-light">{candidate.percent}%</div>
                                            <div id='votesTotal' className='text-xs'>{candidate.votes.toLocaleString('en')} total votes</div>
                                        </div>
                                        {candidate.isElected ? <div id="candidateElected" className="text-xs flex align-middle justify-end">
                                            <CheckCircle className="text-base text-green-900" style={{paddingRight: 5, fontSize: 18, color: 'green'}} /> Elected
                                        </div>
                                        :
                                        <div style={{fontSize: 14}}/>
                                        }
                                    </div>
                                </div>
                            </ListItem>
                        )
                    })} */}
                {/* </List> */}
            <div className='uppercase font-mono text-xs dark:text-dark-text3 py-2'>* incumbent</div>

                </div>
            </div>
        </Dialog>
    )
}
