import React, { useEffect, useRef } from 'react'

export default function Loading() {

  return (
    <div aria-label="loading" className='self-center flex h-full justify-center items-center'>
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div> 
    </div>
  )
}
