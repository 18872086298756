import React, {useState, useEffect, useContext} from 'react'

import {DataContext} from '../../dataContext'
import MajorityMeter from '../../utilities/MajorityMeter';
import Declaration from '../../utilities/Declaration';
import LiveUpdates from '../../utilities/LiveUpdates';
import YearButtons from '../../utilities/YearButtons';
import Party from '../../utilities/Party';

export default function App() {
    const {data} = useContext(DataContext)
	const [parties, setParties] = useState([])

	const {results} = data

	let totalSeats = data.config ? data.config.seats : 0
	if (!data.currentYear && data.config.seats_prev) {
		totalSeats = data.config.seats_prev
	}	
	let majoritySeats = data.config ? data.config.majority : 0
	if (!data.currentYear && data.config.majority_prev) {
		majoritySeats = data.config.majority_prev
	}
    useEffect(()=> {
        if (results.party) {
			setParties([])
            results.party.partyResults.map((party, i)=>{
				const color = getColor(party.nameShort)
				setParties(oldArray=>[...oldArray, {partyName: party.nameShort,id:i, seats: party.seats, text: color.text, color: color.color, votesPercent: party.votesPercent,votesTotal: party.votes}])
				return;
			})
		}

    }, [results])

	const getColor = (partyName) => {
		let color = {
			color: '#D8D9D9', 
			text: 'black'
		}
		try {
			if (data.config.parties) {
				let parsedParty = data.config.parties.find(el=>el.nameShort === partyName)
				if (parsedParty) {
						color = {
							color: parsedParty.chiclet, 
							text: parsedParty.text
						}
						return color
					}
				} 
			let party = results.party.partyResults.find(el=>el.nameShort === partyName)
			if (party) {
				color.color = party.color
				color.text = 'white'
			}
			return color
		} catch (e) {
			return color
		}
	}


    return (
        <div className={'classes.barchartApp h-full max-h-[300px] relative'}>
            {data.error && <div className="p-2 text-center mx-auto dark:text-dark-text">Election Data Currently Unavailable</div>}
            {(data.config && results.party && !data.error) && 
             <div className={'classes.main flex flex-col h-full'} tabIndex="0" aria-labelledby='widgetTitle'>
                <div className={'classes.titleRow flex justify-between'}>
					<div className={'classes.left'}>
						<h2 id="widgetTitle" className={'classes.title text-lg xs:text-2xl dark:text-dark-text font-bold'}>{data.config.title}</h2>
					</div>
					{(data.config.prevyear) 
					 && 
						<YearButtons />
					}
				</div>
				{results.declaration && <Declaration declaration={results.declaration} showLeading={true} majority={majoritySeats}/>}
                <div className={'classes.barchart pt-8 flex-grow'} tabIndex="0" aria-label={`${majoritySeats} seats needed for majority win`}>
                    <MajorityMeter limit={false} longText={false} seatTotal={totalSeats} majority={majoritySeats} majorityPercent={(majoritySeats/totalSeats)*100} configParties={data.config.parties} data={results.party}/>
                </div>
                
                <div className={'flex justify-around gap-2'}>
                    {parties.sort((a,b)=>{
						if (a.seats > b.seats) {
							return -1
						} else if (a.seats < b.seats) {
							return 1
						} else return 0
					}).map(party=>{
						let colors = null
						if (data.config.parties) {
							let selectedParty = data.config.parties.find(el=>el.nameShort === party.partyName)
							colors = selectedParty ? selectedParty : null
						}
						return <Party key={party.id} party={party} />
					})}
                </div> 
				{data.config.election_day &&
					<LiveUpdates time={results.party.generated} />
				}
            </div>
            }
        </div>
    )
}
