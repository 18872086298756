export const PARTIES = 
    { 
        "ns_2021": [
        {
            "nameShort": "PC", 
            "name": "Progressive Conservative", 
            "color": "rgb(0, 51, 153)", 
            "font": "#FFFFFF"
        },{
            "nameShort": "GRN", 
            "name": "Green", 
            "color": "rgb(78, 170, 60)", 
            "font": "#FFFFFF"
        },{
            "nameShort": "NDP", 
            "name": "New Democratic Party", 
            "color": "#EB6D00", 
            "font": "#161725"
        }
        ],
        "on_2022":[ 
        {
            "nameShort": "PC", 
            "name": "Progressive Conservative", 
            "color": "#002AFF", 
            "font": "#FFFFFF"
        },{
            "nameShort": "LIB", 
            "name": "Liberal",
            "color": "#D71920", 
            "font": "#FFFFFF"
        },{
            "nameShort": "NDP", 
            "name": "New Democratic Party", 
            "color": "#EB6D00", 
            "font": "#161725"
        },{
            "nameShort": "GRN", 
            "name": "Green", 
            "color": "#288644", 
            "font": "#FFFFFF"
        },{
            "nameShort": "BQ", 
            "name": "BQ",
            "color": "#36949B", 
            "font": "#FFFFFF"
        },{
            "nameShort": "OTH", 
            "name": "Other", 
            "color": "rgb(114, 118, 133)", 
            "font": "#FFFFFF"
        },{
            "nameShort": "IND", 
            "name": "Other", 
            "color": "rgb(114, 118, 133)", 
            "font": "#161725"
        }
    ]
}
