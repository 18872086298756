import React, { Suspense, useEffect, useState } from "react";
import "./index.css";

import {
	HashRouter as Router, 
	Routes, 
	Route, 
	Link,
	useParams,
	useSearchParams,
	useLocation
  } from "react-router-dom";

import {
  FormControl,
  InputLabel, 
  Select, 
  MenuItem,
  ThemeProvider,
  createTheme
} from '@mui/material'

//Components

import SeatApp from './components/SeatApp/App' 
import GraphApp from './components/GraphApp/App'
import BarchartApp from './components/BarchartApp/App'
import FilteredResultsApp from "./components/FilteredResultsApp/App";
import LeadersApp from './components/LeadersApp/App';
import OverviewApp from './components/OverviewApp/App'
import Loading from './utilities/Loading'

import DataContextProvider from './dataContext'
import {LanguageSwitch, DarkmodeSwitch} from "./utilities/LanguageSwitch";

const MapApp = React.lazy(()=>import('./components/MapApp/App'));

const InstantArticleApp = React.lazy(()=>import('./components/InstantArticle/InstantArticleApp'));


const App = () => {

	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home/>} />
				<Route path="/map/:prov/:year/" element={<Map />} />
				<Route path="/map/:prov/:year/:riding" element={<Map />} />
				<Route path="/seat/:prov/:year" element={<Suspense fallback={<Loading />}>
					<DataContextProvider>
						<SeatApp />
					</DataContextProvider>
					</Suspense>} />
				<Route path="/graph/:prov/:year" element={<DataContextProvider>
					<GraphApp />
				</DataContextProvider>} />
				<Route path="/barchart/:prov/:year" element={<DataContextProvider>
					<BarchartApp />
					</DataContextProvider>
					} />
				<Route path="/filteredResults/:prov/:year" element={<DataContextProvider>
					<FilteredResultsApp />
					</DataContextProvider>
					} />
				<Route path="/filteredResults/:prov/:year/:loadfilter" element={<DataContextProvider>
					<FilteredResultsApp />
					</DataContextProvider>
					} />
				<Route path="/leaders/:prov/:year" element={<DataContextProvider>
						<LeadersApp />
					</DataContextProvider>} />
				<Route path="/instantarticle/:prov/:year" element={<Suspense fallback={<Loading />}>
					<DataContextProvider>
						<InstantArticle />
					</DataContextProvider>
					</Suspense>
					} />
				<Route path="/instantarticle/:prov/:year/:riding" element={
					<Suspense fallback={<Loading />}>
						<DataContextProvider>
							<InstantArticle />
						</DataContextProvider>
					</Suspense>
				} />
				<Route path="/overview/:prov/:year" element={<Suspense fallback={<div>Loading..</div>}>
					<DataContextProvider>
						<OverviewApp />
					</DataContextProvider>
					</Suspense>
					} />
				</Routes>
	  </Router>
	);
}

const Home = () => {
  const [prov, setProv] = useState({prov: 'ns', year: 2024});
  const [darkMode, setDarkMode] = useState(false)
  const [lang, toggleLang] = useState('en')
  let [searchParams] = useSearchParams();
  let langParam = searchParams.get("lang") ? searchParams.get("lang") : "en"
  let darkmodeParam = searchParams.get('darkmode') && searchParams.get("darkmode")
	useEffect(()=> {
		const forceDarkMode = darkmodeParam ? Boolean(darkmodeParam) : false
		setDarkMode(forceDarkMode)
	}, [])
	useEffect(()=>{
		document.documentElement.classList.toggle('dark', darkMode);
	}, [darkMode])

	const handleToggleLang = (e)=>{
		toggleLang(prev=>(prev==='en'?'fr':'en'))
	}
	const handleDMToggle = (e)=>{
		setDarkMode(prev=>!prev)
	}
	const theme = createTheme({
		palette: {
		  mode: 'dark',
		  primary: {
			main: '#231F20'
		  },
		  background: {
			default: '#231F20'
		}
		},
	  });
	return (
	<div className="p-4 h-screen bg-white text-black dark:bg-dark-bg dark:text-dark-text">
		<ThemeProvider theme={darkMode&&theme}>
		<FormControl className="dark: max-w-lg" fullWidth>
			<InputLabel className="bg-white dark:bg-dark-bg px-2" id="demo-simple-select-label">Select Election</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={prov}
				onChange={(e)=>setProv(e.target.value)}
			>
				{window.CONFIG.elections.map((el)=>{
				return <MenuItem value={el}>{el.prov} - {el.year}</MenuItem>
				})}
			</Select>
		</FormControl>
		<ul className="px-1">
			<li className="text-globallink hover:underline">
				<Link to={`/?darkmode=${darkMode}&lang=${lang}`}>Home</Link>
			</li>
			<li className=" text-globallink hover:underline">
				<Link to={`/map/${prov.prov}/${prov.year}?darkmode=${darkMode}&lang=${lang}`}>Map App</Link>
			</li>
			<li className=" text-globallink hover:underline">
				<Link to={`/barchart/${prov.prov}/${prov.year}?darkmode=${darkMode}&lang=${lang}`}>Barchart App</Link>
			</li>
			<li className=" text-globallink hover:underline">
				<Link to={`/graph/${prov.prov}/${prov.year}?darkmode=${darkMode}&lang=${lang}`}>Graph App</Link>
			</li>
			<li className=" text-globallink hover:underline">
				<Link to={`/seat/${prov.prov}/${prov.year}?darkmode=${darkMode}&lang=${lang}`}>Seat App</Link>
			</li>
			<li className=" text-globallink hover:underline">
				<Link to={`/instantarticle/${prov.prov}/${prov.year}?darkmode=${darkMode}&lang=${lang}`}>Instant Article</Link>
			</li>
			<li className=" text-globallink hover:underline">
				<Link to={`/filteredResults/${prov.prov}/${prov.year}/close?darkmode=${darkMode}&lang=${lang}`}>Playlist Results</Link>
			</li>
			<li className=" text-globallink hover:underline">
				<Link to={`/overview/${prov.prov}/${prov.year}?darkmode=${darkMode}&lang=${lang}`}>Simple Overview App w/link</Link>
			</li>
			<li className=" text-globallink hover:underline">
				<Link to={`/leaders/${prov.prov}/${prov.year}?darkmode=${darkMode}&lang=${lang}`}>Party Leaders</Link>
			</li>
		</ul>
		<div className="flex py-4 gap-4">
			<div className="flex flex-col">
				<div className="font-semibold">Toggle Language</div>
				<LanguageSwitch checked={lang === 'en'} handleLangToggle={handleToggleLang}/>
			</div>
			<div className="border border-grey-600" />
			<div className="flex flex-col">
				<div className="font-semibold">Toggle Darkmode</div>
				<DarkmodeSwitch checked={darkMode} handleDMToggle={handleDMToggle} />
			</div>
		</div>
		</ThemeProvider>
	</div>
	)
}

const Map = () => {
    return (
        <Suspense fallback={<Loading />}>
			<DataContextProvider>
				<MapApp showBanner={true}/>
			</DataContextProvider>
        </Suspense>
    )
}

const InstantArticle = () => {
	return (
		<div className={"flex relative  flex-col h-full gap-1"}>
			<div className={'max-h-40'}>
				<InstantArticleApp />
			</div>
			<div className={'max-h-full flex-grow-1 overflow-hidden relative'} >
				<MapApp showBanner={false}/>
			</div>
		</div>
	)
}
export default App;

// style={{flexGrow: '1', width: '100%', position: 'relative', overflow: 'auto'}}