import {PARTIES} from './colourOverrides';

export default function getColorOverride(partycode, election) {
    try {
        let override = PARTIES[election].find(party=>party.nameShort === partycode)
        // console.log('override', partycode, override)
        if (override) {
            return override
        }
        else return null
    } catch (e) {
        return null;
    }
}

export const toMatchCase = (word) => {
    return word.trim().toLowerCase().replace(/[^a-zA-Z]|\s/g,"")
}