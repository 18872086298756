import React, {useEffect, useState} from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Declaration = ({declaration, majority, showLeading}) => {
    const [declarationText, setDeclarationText] = useState('')

    useEffect(()=> {
        getDeclaration()
    }, [declaration])

    const getDeclaration = () => {
        if (declaration.overallResult.partyName || declaration.overallResult.resultText) {
            let text = declaration.overallResult.partyNameShort + ' ' + declaration.overallResult.resultText;
            setDeclarationText(text)
        } else 
            setDeclarationText('')
    
    }   
    
    const GetLeading = () => {
		return (
            <div id="declarationText" className={'dark:text-dark-text'}>
				<span className='' id="majority">{majority} seats needed for majority</span>
            </div>
        )
    }

    if (declarationText) {
        return (
            <div 
                className={'test font-bold font-light dark:text-dark-text flex flex-nowrap gap-1 text-sm sm:text-base items-center'} 
                tabIndex="0" 
                role="none" 
                aria-label={`election declaration ${'props.declarationText'}`}>
                {(!declarationText && showLeading) && <GetLeading />}
                {declarationText && <><CheckCircleIcon className='text-xl md:text-2xl text-green-700'/><span>{declarationText}</span></>}
            </div>
        )
    
    } 
    return;
}

export default Declaration