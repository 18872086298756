import React, {useContext} from 'react'
import { DataContext } from '../dataContext'

export default function Yearbuttons() {
    const {data, changeYear} = useContext(DataContext)

    return (
        <div className="border p-1 rounded-sm dark:border-dark-border border-border flex gap-1">
            <button className={ 'py-2 px-4 text-sm rounded-sm' + (data.currentYear ? `  hover:bg-globalredhover bg-globalred text-white border border-globalred hover:border-globalredhover ` : 'bg-white text-black text-sm border dark:bg-dark-bg dark:border-dark-bg dark:text-dark-text border-white hover:border-globalred')}  onClick={()=>changeYear(true)}>{data.config.currentyear}</button>
            <button className={ 'py-2 px-4 text-sm rounded-sm' + (!data.currentYear ? ` hover:bg-globalredhover bg-globalred text-white border border-globalred hover:border-globalredhover` : 'bg-white text-black text-sm border dark:bg-dark-bg dark:border-dark-bg dark:text-dark-text  border-white hover:border-globalred')}  onClick={()=>changeYear(false)}>{data.config.prevyear}</button>
        </div>
    )
}
